<template>
  <div class="equipment" id="ruleWrapper">
    <!-- <div class="breadcrumb"></div> -->
    <div class="e_content bgFFF">
      <div class="ruleMessage1">
        <h2 class="title">
          <div class="title_icon"></div>
          <span>优惠券编辑</span>
        </h2>
        <el-form
          :model="formInline"
          :rules="rules"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <div class="f-top">
            <el-form-item
              :label="$t('searchModule.Coupon_Name')"
              prop="couponName"
              :disabled="false"
            >
              <el-input
                type="text"
                placeholder="请输入优惠券名称"
                v-model.trim="formInline.couponName"
                auto-complete="off"
                style="width: 250px"
                :disabled="isDetail"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="模式" prop="couponMode">
              <el-radio-group
                @change="changeCouponMode"
                v-model="formInline.couponMode"
                :disabled="isDetail"
              >
                <el-radio :label="0">自有券</el-radio>
                <el-radio :label="1">合作券</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="合作方"
              prop="partnerName"
              v-if="formInline.couponMode == 0 || storeList.length <= 0"
            >
              <el-input
                placeholder="请输入合作方"
                :disabled="formInline.couponMode == 0 || isDetail"
                v-model.trim="formInline.partnerName"
                style="width: 250px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="合作方" v-else prop="storeIds">
              <el-select
                v-model.trim="formInline.storeIds"
                :disabled="isDetail"
                multiple
                placeholder="合作方名称"
                :remote-method="remoteMethod"
                :loading="loading"
                style="width: 250px"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.storeId"
                  :label="item.storeName"
                  :value="item.storeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务类型" prop="businessType">
              <el-select v-model="formInline.businessType" :disabled="isDetail">
                <el-option label="停车" :value="0" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Coupon_type')" prop="couponType">
              <el-select
                v-model.trim="formInline.couponType"
                style="width: 250px"
                :disabled="isDetail"
              >
                <el-option label="现金券" :value="0" />
                <el-option label="折扣券" :value="1" />
                <el-option label="全免券" :value="2" />
                <el-option label="小时券" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item label="优惠券面值" prop="faceValue1" v-if="formInline.couponType === 0">
              <el-input
                placeholder="金额"
                maxlength="4"
                v-model.trim="formInline.faceValue1"
                style="width: 100px"
                :disabled="isDetail"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item label="使用条件" prop="threshold1" v-if="formInline.couponType === 0">
              <span>满 </span>
              <el-input
                placeholder="金额"
                maxlength="4"
                v-model.trim="formInline.threshold1"
                style="width: 80px"
                :disabled="isDetail"
              ></el-input>
              <span> 元及以上使用</span>
              <!--<span style="color: #c0ccda;"> *无门槛请设0</span>-->
            </el-form-item>
            <el-form-item label="打折比例" prop="faceValue2" v-if="formInline.couponType === 1">
              <el-input
                placeholder="百分比"
                maxlength="4"
                v-model.trim="formInline.faceValue2"
                style="width: 120px"
                :disabled="isDetail"
              />
              %
            </el-form-item>
            <el-form-item label="折扣金额上限" prop="threshold2" v-if="formInline.couponType === 1">
              <el-input
                placeholder="金额"
                maxlength="4"
                v-model.trim="formInline.threshold2"
                style="width: 120px"
                :disabled="isDetail"
              />
              <span style="color: #ccc">*无上限设为0</span>
            </el-form-item>
            <el-form-item label="优惠券面值" prop="faceValue3" v-if="formInline.couponType === 3">
              <span slot="label">
                <span style="vertical-align: middle">优惠券面值</span>
                <el-tooltip
                  content="优惠券面值是商家给车主的优惠时长，是车主能享受的最长停车时长优惠"
                  placement="top"
                >
                  <span class="help">?</span>
                </el-tooltip>
              </span>
              <el-input
                placeholder="请输入小时"
                v-model.trim="formInline.faceValue3"
                style="width: 120px"
                :disabled="isDetail"
                maxlength="3"
              ></el-input>
              <span>&nbsp;小时</span>
            </el-form-item>
            <el-form-item prop="debtHours" label="欠费时长不超过">
              <!-- <el-select v-model.trim="formInline.debtHours" style='width:80px' :disabled="isDetail">
                <el-option label="不限" :value="9999"/>
                <el-option label="0" :value="0"/>
                <el-option label="24" :value="1"></el-option>
                <el-option label="48" :value="2"></el-option>
                <el-option label="72" :value="3"></el-option>
              </el-select> -->
              <el-input
                v-model.trim="formInline.debtHours"
                style="width: 80px"
                :disabled="isDetail"
                maxlength="4"
              />
              <span> 小时可使用</span>
            </el-form-item>
            <el-form-item label="有效日期" prop="effectType">
              <el-radio-group v-model="formInline.effectType" :disabled="isDetail">
                <el-radio :label="0">固定日期</el-radio>
                <el-radio :label="1">自领取日起</el-radio>
              </el-radio-group>
              <div>
                <el-form-item v-if="formInline.effectType == 0" prop="effectTime">
                  <el-date-picker
                    :picker-options="pickerOptions"
                    v-model="formInline.effectTime"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :disabled="isDetail"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item prop="effectDays" v-if="formInline.effectType == 1">
                  <span>自领取后 </span>
                  <el-input
                    v-model.trim="formInline.days"
                    maxlength="3"
                    style="width: 60px"
                    :disabled="isDetail"
                  />
                  天
                  <el-input
                    v-model.trim="formInline.hours"
                    maxlength="3"
                    style="width: 60px"
                    :disabled="isDetail"
                  />
                  时
                  <el-input
                    v-model.trim="formInline.minutes"
                    maxlength="3"
                    style="width: 60px"
                    :disabled="isDetail"
                  />
                  <span> 分内有效</span>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="可用终端" prop="useClient">
              <el-checkbox-group v-model="formInline.useClient" :disabled="isDetail">
                <el-checkbox label="1">APP</el-checkbox>
                <el-checkbox label="2">微信公众号</el-checkbox>
                <el-checkbox label="3">微信小程序</el-checkbox>
                <el-checkbox label="4">支付宝生活号</el-checkbox>
                <el-checkbox label="5">支付宝小程序</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="使用范围" prop="scope">
              <el-select style="width: 250px" v-model.trim="formInline.scope" :disabled="isDetail">
                <el-option label="全部" :value="1" />
                <el-option label="按道路/车场" :value="2" />
                <el-option label="按商户" :value="3" />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="formInline.scope === 3"
              :label="$t('searchModule.Merchant')"
              prop="merchantValue"
            >
              <transfer
                :searchUrl="'/acb/2.0/operation/nameList'"
                :params="'likeOperationName'"
                :placeholder="'请输入商户名称'"
                :value="formInline.merchantValue"
                :isDetail="isDetail"
                :checkItemArr="merchantCheckItemArr"
                :anotherName="{
                  key: 'operationId',
                  label: 'operationName',
                }"
                @setSelectVal="setMerchantValue"
              >
              </transfer>
            </el-form-item>
            <el-form-item
              v-if="formInline.scope === 2"
              :label="$t('searchModule.Road_or_Parking_Lot')"
              prop="yardValue"
            >
              <transfer
                :checkItemArr="yardCheckItemArr"
                :isDetail="isDetail"
                :value="formInline.yardValue"
                @setSelectVal="setParkValue"
              >
              </transfer>
            </el-form-item>
            <el-form-item label="结算模式" v-if="formInline.couponMode == 1" prop="settlementMoney">
              <span slot="label">
                <span style="vertical-align: middle">结算模式</span>
                <el-tooltip placement="top">
                  <div slot="content">
                    <span>结算模式是跟商家进行结算的费用方式：</span><br />
                    <span>固定金额：每成功减免一次按固定金额与商家结算；</span><br />
                    <span
                      >实际金额：每成功减免一次按车主在这次减免中实际优惠的金额与商家结算；结算费用未超过上限费用的按实际金额结算，超过的按上限费用结算；</span
                    >
                  </div>
                  <span class="help">?</span>
                </el-tooltip>
              </span>
              <el-radio-group
                @change="changeMode"
                v-model="formInline.settlementMode"
                :disabled="isDetail"
              >
                <el-radio :label="1">固定金额</el-radio>
                <el-radio :label="2">实际金额</el-radio>
              </el-radio-group>
              <div>
                <el-input
                  v-if="formInline.settlementMode == 1"
                  v-model.trim="formInline.settlementMoney"
                  style="width: 250px"
                  :disabled="isDetail"
                ></el-input>
                <el-input
                  v-if="formInline.settlementMode == 2"
                  v-model.trim="formInline.settlementMoney"
                  style="width: 250px"
                  :disabled="isDetail"
                  placeholder="请输入结算上限，请看左侧说明"
                ></el-input>
                <span>&nbsp;元</span>
              </div>
            </el-form-item>
            <el-form-item label="使用说明" prop="remark">
              <el-input
                rows="4"
                resize="none"
                type="textarea"
                style="width: 350px"
                v-model="formInline.remark"
                :disabled="isDetail"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div style="margin: 0 auto; width: 250px; margin-bottom: 20px">
        <el-button v-if="!isDetail" type="primary" @click="submitData()">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import transfer from "@/components/transfer";

export default {
  data() {
    let settlementMoney = (rule, value, callback) => {
      // if (this.settlementMode == 2) {
      //   callback();
      // }
      if (this.formInline.settlementMode != 2) {
        if (value === "") {
          callback(new Error("请输入金额"));
        }
      }
      if (value) {
        if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
          callback(new Error("必须为数字类型"));
        } else if (value < 0 || value > 99) {
          callback(new Error("金额范围在0-99"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          if (time.getFullYear() < new Date().getFullYear()) {
            return true;
          } else if (
            time.getFullYear() <= new Date().getFullYear() &&
            time.getMonth() < new Date().getMonth()
          ) {
            return true;
          } else if (
            time.getFullYear() <= new Date().getFullYear() &&
            time.getMonth() <= new Date().getMonth() &&
            time.getDate() < new Date().getDate()
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      Dateflag: true,
      errMsg: "",
      couponRuleId: this.$route.query.couponRuleId,
      isDetail: this.$route.path === "/couponDetail",
      sendData: {},
      defaultTime: [],
      scopeList: [],
      merchantCheckItemArr: [],
      yardCheckItemArr: [],
      loading: false,
      list: [],
      storeList: [], // 合作方列表
      formInline: {
        couponName: "",
        couponMode: 0,
        partnerName: "爱泊车",
        businessType: 0,
        couponType: 0,
        faceValue1: "",
        faceValue2: "",
        faceValue3: "",
        threshold1: "",
        threshold2: "",
        debtHours: "",
        effectType: 0,
        effectTime: [],
        delayTime: "",
        useClient: [],
        scope: "",
        remark: "",
        merchantValue: [],
        yardValue: [],
        days: "",
        hours: "",
        minutes: "",

        storeIds: [],
        settlementMode: 1, // 结算模式
        settlementMoney: "", // 结算模式金额
      },
      rules: {
        couponName: [
          {
            required: true,
            message: "请输入优惠券名称",
            trigger: "blur",
          },
        ],
        couponMode: [
          {
            required: true,
            message: "请输入优惠券模式",
            trigger: "blur",
          },
        ],
        partnerName: [
          {
            required: true,
            message: "请输入合作商名称",
            trigger: "blur",
          },
        ],
        storeIds: [
          {
            required: true,
            message: "请选择合作方",
            trigger: "blur",
          },
        ],
        businessType: [
          {
            required: true,
            message: "请选择业务类型",
            trigger: "change",
          },
        ],
        couponType: [
          {
            required: true,
            message: "请选择优惠券类型",
            trigger: "change",
          },
        ],
        faceValue1: [
          {
            required: true,
            message: "请输入优惠券面值",
            trigger: "blur",
          },
          {
            validator: this.checkMoney2,
            trigger: "blur",
          },
        ],
        faceValue3: [
          {
            required: true,
            message: "请输入优惠券面值",
            trigger: "blur",
          },
          {
            validator: this.checkMoney3,
            trigger: "blur",
          },
        ],
        settlementMoney: [
          // {
          //   required: true,
          //   message: '请输入金额',
          //   trigger: 'blur'
          // },
          {
            required: true,
            validator: settlementMoney,
            trigger: "blur",
          },
        ],
        threshold1: [
          {
            required: true,
            message: "请输入使用条件",
            trigger: "blur",
          },
          {
            validator: this.checkMoney1,
            trigger: "blur",
          },
        ],
        faceValue2: [
          {
            required: true,
            message: "请输入打折比例",
            trigger: "blur",
          },
          {
            validator: this.checkMoney,
            trigger: "blur",
          },
        ],
        threshold2: [
          {
            required: true,
            message: "请输入折扣金额上限",
            trigger: "blur",
          },
          {
            validator: this.checkMoney1,
            trigger: "blur",
          },
        ],
        debtHours: [
          {
            required: true,
            message: "请输入欠费时长",
            trigger: "blur",
          },
          {
            pattern: /^[1-9]\d*$/,
            message: "欠费时长只能输入1-9999的正整数",
          },
        ],
        effectType: [
          {
            required: true,
            message: "请选择有效期",
            trigger: "change",
          },
        ],
        useClient: [
          {
            required: true,
            message: "请选择可用终端",
            trigger: "change",
          },
        ],
        scope: [
          {
            required: true,
            message: "请选择优惠券使用范围",
            trigger: "blur",
          },
        ],
        merchantValue: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        yardValue: [
          {
            required: true,
            message: "请选择道路/车场",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入使用说明",
            trigger: "blur",
          },
          {
            validator: this.checkDescription,
            trigger: "blur",
          },
        ],
        settlementMode: [
          {
            required: true,
            message: "请输入结算金额",
            trigger: "blur",
          },
          {
            pattern: /^[1-9]\d*$/,
            message: "结算金额只能输入1-9999的正整数",
          },
        ],
      },
      merchantData: [],
      yardData: [],
    };
  },
  components: {
    transfer,
  },
  methods: {
    /**
     * 触发结算模式
     */
    changeMode(value) {
      // if (value == 2) {
      //   delete this.formInline.settlementMoney
      // }
    },

    setParkValue(val) {
      this.formInline.yardValue = val;
    },
    setMerchantValue(val) {
      this.formInline.merchantValue = val;
    },
    getMerchantData() {
      const opt = {
        method: "get",
        url: "/acb/2.0/operation/nameList",
        data: {},
        success: (res) => {
          res.value.forEach((item) => {
            const _item = {
              key: item.operationId,
              label: item.operationName,
              disabled: false,
            };
            if (this.isDetail) {
              _item.disabled = true;
            }
            this.merchantData.push(_item);
          });
        },
      };
      this.$request(opt);
    },
    changeCouponMode(val) {
      if (val == 0) {
        this.formInline.partnerName = "爱泊车";
      } else if (val == 1) {
        this.formInline.partnerName = "";
        this.formInline.storeIds = [];
      }
    },
    checkDescription: (rule, value, callback) => {
      if ((value.length < 0 || value.length > 200) && value) {
        callback(new Error("描述长度在200字以内"));
      } else {
        callback();
      }
    },
    checkMoney: (rule, value, callback) => {
      if (!/^[1-9]\d*$/.test(value)) {
        callback(new Error("必须为正整数"));
      } else if ((value <= 0 || value >= 100) && value) {
        callback(new Error("金额在0到100之间"));
      } else if (value == "") {
        callback(new Error("请输入金额"));
      } else {
        callback();
      }
    },
    checkMoney1: (rule, value, callback) => {
      if (!/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[0-9][0-9]*\.\d{1,2})))$/.test(value)) {
        callback(new Error("必须为数字类型"));
      } else if (value === "") {
        callback(new Error("请输入金额"));
      } else {
        callback();
      }
    },
    checkMoney2: (rule, value, callback) => {
      if (!/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[0-9][0-9]*\.\d{1,2})))$/.test(value)) {
        callback(new Error("必须为数字类型"));
      } else if (value == 0) {
        callback(new Error("面值不能为0"));
      } else if (value === "") {
        callback(new Error("请输入金额"));
      } else {
        callback();
      }
    },
    checkMoney3: (rule, value, callback) => {
      // else if (!(/^[0-9]+(.[0-9]{1})?$/.test(value))) {
      //   callback(new Error('必须为一位小数'));
      // }
      if (value === "") {
        callback(new Error("请输入金额"));
      } else if (!/^\d*(\.\d{1})?$/.test(value)) {
        callback(new Error("支持一位小数"));
      } else if (value == 0) {
        callback(new Error("面值不能为0"));
      } else if (value < 0.5) {
        callback(new Error("面值最小为半小时"));
      } else {
        callback();
      }
    },
    formatMinutes() {
      let total = 0;
      if (this.formInline.days) {
        total += 24 * 60 * this.formInline.days;
      }
      if (this.formInline.hours) {
        total += 60 * this.formInline.hours;
      }
      if (this.formInline.minutes) {
        total += 1 * this.formInline.minutes;
      }
      return total;
    },
    formatDelayTime(delayTime) {
      let surplus = delayTime;
      let days = parseInt(delayTime / (24 * 60));
      days = days > 0 ? days : 0;
      surplus = surplus - days * 24 * 60;
      let hours = parseInt((delayTime - days * 24 * 60) / 60);
      hours = hours > 0 ? hours : 0;
      surplus = surplus - hours * 60;
      let minutes = surplus;
      minutes = minutes > 0 ? minutes : 0;
      this.formInline.days = days;
      this.formInline.hours = hours;
      this.formInline.minutes = minutes;
    },

    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.storeList = this.list.filter((item) => {
            return String(item.storeName).indexOf(query) > -1;
          });
        }, 200);
      } else {
        this.storeList = [];
      }
    },

    /**
     * 获取商家列表
     */
    getStoreList() {
      const opt = {
        method: "get",
        url: "/acb/2.0/store/listStores",
        data: {},
        success: (res) => {
          this.storeList = res.value || [];
          this.list = this.storeList.map((item) => {
            return { storeId: item.storeId, storeName: item.storeName };
          });
        },
      };
      this.$request(opt);
    },

    submitData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 面值不能大于应收金额
          const couponType = this.formInline.couponType;
          let threshold = 0;
          let faceValue = 0;
          if (couponType === 0) {
            faceValue = this.formInline.faceValue1;
            threshold = this.formInline.threshold1;
            if (threshold <= 0) {
              this.$alert("添加优惠券现金券满x元可用，x应大于0");
              return;
            }
          } else if (couponType === 1) {
            faceValue = this.formInline.faceValue2;
            threshold = this.formInline.threshold2;
          }
          if (couponType === 0 && threshold * 1 !== 0 && threshold * 1 < faceValue * 1) {
            this.$alert("优惠券面值应小于等于满减金额，请核查", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
            return;
          }
          if (this.formInline.effectType === 0 && this.formInline.effectTime.length === 0) {
            this.$alert("请选择有效日期", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
            return;
          } else if (
            this.formInline.effectType === 1 &&
            this.formInline.days === "" &&
            this.formInline.hours === "" &&
            this.formInline.minutes === ""
          ) {
            this.$alert("请填写有效日期", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
            return;
          }
          if (this.formInline.effectType === 1) {
            const days = this.formInline.days;
            const hours = this.formInline.hours;
            const minutes = this.formInline.minutes;
            const reg = /^[0-9]\d*$/;
            if (!reg.test(days) || !reg.test(hours) || !reg.test(minutes)) {
              this.$alert("有效日期日、小时、分钟格式不正确", this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
              return;
            }
            if (days == 0 && hours == 0 && minutes == 0) {
              this.$alert("日、小时、分钟不能全为0", this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
              return;
            }
            if (days < 0 || days > 365) {
              this.$alert("有效日期天数范围在0至365之间", this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
              return;
            }
            if (hours < 0 || hours > 23) {
              this.$alert("有效日期小时范围在0至23之间", this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
              return;
            }
            if (minutes < 0 || minutes > 59) {
              this.$alert("有效日期分钟范围在0至59之间", this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
              return;
            }
          }
          const tempData = {
            couponName: this.formInline.couponName,
            couponMode: this.formInline.couponMode,
            partnerName: this.formInline.partnerName,
            businessType: this.formInline.businessType,
            couponType: this.formInline.couponType,
            debtHours: this.formInline.debtHours,
            effectType: this.formInline.effectType,
            useClient: this.formInline.useClient.join(","),
            scope: this.formInline.scope,
            remark: this.formInline.remark,
            storeIds: this.formInline.storeIds.join(","),
            settlementMode: this.formInline.settlementMode,
            settlementMoney: this.formInline.settlementMoney,
          };
          if (this.formInline.couponType === 0) {
            tempData.faceValue = Math.round(this.formInline.faceValue1 * 100);
            tempData.threshold = Math.round(this.formInline.threshold1 * 100);
          } else if (this.formInline.couponType === 1) {
            tempData.faceValue = this.formInline.faceValue2;
            tempData.threshold = Math.round(this.formInline.threshold2 * 100);
          } else if (this.formInline.couponType === 2) {
            tempData.faceValue = 0;
            tempData.threshold = 0;
          } else if (this.formInline.couponType === 3) {
            tempData.faceValue = Math.round(this.formInline.faceValue3 * 60);
            tempData.threshold = 0;
          }
          if (tempData.scope === 3) {
            tempData.scopeIds = this.formInline.merchantValue.join(",");
          }
          if (tempData.scope === 2) {
            tempData.scopeIds = this.formInline.yardValue.join(",");
          }
          if (this.formInline.effectType == 0) {
            tempData.effectStartTime = this.formInline.effectTime[0] + " 00:00:00";
            tempData.effectEndTime = this.formInline.effectTime[1] + " 23:59:59";
          } else if (this.formInline.effectType == 1) {
            tempData.delayTime = this.formatMinutes();
          }
          if (this.couponRuleId) {
            tempData.couponRuleId = this.couponRuleId;
          }
          if (this.formInline.couponMode == 0) {
            delete tempData.settlementMode;
            delete tempData.settlementMoney;
            delete tempData.storeIds;
          } else {
            if (this.formInline.settlementMode == 1 && this.formInline.settlementMoney == "") {
              this.$alert("请输入固定金额", this.$t("pop_up.Tips"), {
                confirmButtonText: this.$t("pop_up.Determine"),
              });
              return;
            }
            tempData.settlementMoney =
              this.formInline.settlementMoney != ""
                ? Math.round(this.formInline.settlementMoney * 100)
                : "";
          }
          const url = this.couponRuleId ? "/acb/2.0/coupon/updateById" : "/acb/2.0/coupon/create";
          const opt = {
            method: "post",
            url: url,
            data: tempData,
            success: (res) => {
              this.$router.go(-1);
            },
          };
          this.$request(opt);
        }
      });
    },
    getDetailInfo() {
      const opt = {
        method: "get",
        url: "/acb/2.0/coupon/byId/" + this.couponRuleId,
        data: {},
        success: (res) => {
          const value = res.value;
          this.formInline.couponName = value.couponName;
          this.formInline.couponMode = value.couponMode;
          this.formInline.partnerName = value.partnerName;
          this.formInline.businessType = value.businessType;
          this.formInline.couponType = value.couponType;
          if (value.couponMode == 1) {
            this.formInline.settlementMode = value.settlementMode;
            // let storeIds = value.storeIds && value.storeIds.split(',');
            // this.formInline.storeIds = storeIds;
            let storeIds = [];
            value.stores &&
              value.stores.forEach((val) => {
                storeIds.push(val.storeId);
              });
            this.formInline.storeIds = storeIds;
          }
          if (value.couponType === 0) {
            this.formInline.faceValue1 = value.faceValue / 100;
            this.formInline.threshold1 = value.threshold / 100;
          } else if (value.couponType === 1) {
            this.formInline.faceValue2 = value.faceValue;
            this.formInline.threshold2 = value.threshold / 100;
          } else if (value.couponType === 3) {
            this.formInline.faceValue3 = value.faceValue / 60;
          }
          // this.formInline.settlementMoney = value.settlementMoney;
          this.formInline.debtHours = value.debtHours;
          this.formInline.effectType = value.effectType;
          if (value.effectType === 0) {
            this.formInline.effectTime = [value.effectStartTime, value.effectEndTime];
          } else {
            const delayTime = value.delayTime;
            this.formatDelayTime(delayTime);
          }
          if (this.formInline.couponMode == 1) {
            this.formInline.settlementMoney = value.settlementMoney
              ? value.settlementMoney / 100
              : "";
          }
          const useClient = value.useClient.split(",");
          this.formInline.useClient = useClient;
          this.formInline.scope = value.scope;
          this.formInline.remark = value.remark;
          if (value.scope === 3) {
            this.formInline.merchantValue = value.scopeIds;
            this.merchantCheckItemArr = value.operationVoList;
          }
          if (value.scope === 2) {
            this.formInline.yardValue = value.scopeIds;
            this.yardCheckItemArr = value.parkNameVOList;
          }
        },
      };
      this.$request(opt);
    },
  },
  mounted() {
    this.getMerchantData();
    this.getStoreList();
    if (this.couponRuleId) {
      this.getDetailInfo();
    }
  },
};
</script>
<style scoped>
.tooltip1 {
  position: absolute !important;
  left: -65px;
  top: 8px;
  z-index: 1;
}

.fuhao {
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 16px;
  border: 2px solid;
  border-color: blue;
  font-size: 30px;
  vertical-align: middle;
}

.equipment .e_content {
  border: 1px solid #c0ccda;
  border-radius: 4px;
  min-height: 550px;
  padding: 0 20px;
  color: #48576a;
}

.equipment .e_content .f-top {
  /* width:900px; */
  margin: 0 auto;
  margin-bottom: 80px;
}

.el-select {
  width: 250px;
}

.breadcrumb {
  height: 35px;
}

.ruleMessage {
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 20px;
}

.title {
  padding-top: 20px;
}

.timerPiker {
  padding-left: 20px;
  position: relative;
}

.timerPiker .errMsg {
  position: absolute;
  left: 120px;
  top: 39px;
  font-size: 12px;
  color: #ff4949;
}

.effectDate {
  margin-right: 10px;
}

.dateForm {
  width: 700px;
  margin: 0 auto;
}

b {
  text-align: center;
  font-weight: normal;
  display: inline-block;
  width: 36px;
  line-height: 36px;
  background: #c0ccda;
}

.addEffictDate {
  display: inline-block;
  cursor: pointer;
}

.addEffictDate:hover {
  color: blue;
}

.help {
  display: inline-block;
  margin-left: 5px;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 15px;
  line-height: 15px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
  cursor: pointer;
}

.timeMax label {
  padding: 0 !important;
  line-height: 16px !important;
}
</style>
<style>
.timeMax label {
  /* padding: 0 !important; */
  line-height: 18px !important;
}
</style>
