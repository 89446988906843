<template>
  <div class="parkNameSearch">
    <el-input prefix-icon="el-icon-search" debounce class="parkName" v-model="input" @input="searchList" :placeholder=placeholder></el-input>
    <el-transfer
        target-order="push"
        @change="handleChange"
        :props="anotherName"
        filterable
        :filter-placeholder= placeholder
        v-model="selectCheck"
        :titles="['未选择', '已选择']"
        :data="dataList">
    </el-transfer>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    // 查询接口地址
    searchUrl: {
      type: String,
      default: '/acb/2.0/systems/loginUser/getParkName'
    },
    // 查询接口关键词key
    params: {
      type: String,
      default: 'parkName'
    },
    // 查询提示文案
    placeholder: {
      type: String,
      default: '请输入停车场名称'
    },
    // 已选ID集合
    value: {
      type: Array,
      default: []
    },
    // 是否是详情页面，禁用组件用
    isDetail: {
      type: Boolean,
      default: false
    },
    // 数组别名
    anotherName: {
      type: Object,
      default: function () {
        return {
          key: 'parkId',
          label: 'parkName'
        }
      }
    },
    // 已选item数组
    checkItemArr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      input: '',
      selectCheck: this.value,
      selectCheckItemArr: this.checkItemArr.length ? this.checkItemArr : [],
      dataList: []
    }
  },
  watch: {
    searchUrl() {
      this.input = ''
      this.selectCheck = []
      this.selectCheckItemArr = []
      this.dataList = []
      this.searchList()
    }
  },
  methods: {
    searchList(val) {
      this.$axios.get(this.searchUrl, {
        data: {
          page: 1,
          pageSize: 999,
          [this.params]: val,
        }
      }).then(res => {
        let resList = res.value.list || res.value
        let list =  [...resList, ...this.selectCheckItemArr]
        list.length && list.forEach(item => {
          item.disabled = this.isDetail;
        });
        this.dataList = this.unique([...resList, ...this.selectCheckItemArr], this.anotherName.label);
      })
    },
    handleChange(val) {
      this.selectCheckItemArr = []
      // 存储已选择的数组
      val.forEach(v => {
        this.selectCheckItemArr.push(this.dataList.find(item => item.parkId === v))
      })
      this.$emit('setSelectVal', val)
    },
    /**
     * 根据数组对象的某个字段去重
     * item.name  是[{name:1}] 根据每条数据的name值来去重
     * */
    unique(arr, val) {
      const res = new Map();
      return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
    }
  },
  mounted() {
    this.searchList()
  }
};
</script>

<style scoped>
.parkNameSearch{
  position: relative;
}
.parkName{
  width: 168px;
  position: absolute;
  top: 56px;
  left: 16px;
  z-index: 2;
}
.parkName >>> input{
  box-sizing: border-box;
  border-radius: 16px;
  padding-right: 10px;
  padding-left: 30px;
  height: 32px;
  font-size: 12px;
}
</style>
